import { ChangeDetectionStrategy, Component, ContentChildren, Input, QueryList, TemplateRef } from '@angular/core';
import { NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import { AbstractControl, ControlContainer, FormArray, FormBuilder, FormGroup, FormGroupDirective, ReactiveFormsModule, ValidationErrors } from '@angular/forms';
import { TooltipModule } from 'ngx-bootstrap/tooltip';
import { FaIconComponent } from '@fortawesome/angular-fontawesome';
import { ValidationErrorsComponent } from 'shared/components/forms/validation-errors/validation-errors.component';

export interface ArrayFormGroup {
  [key: string]: (string | ((control: AbstractControl<any, any>) => ValidationErrors))[] | FormGroup;
}

@Component({
  selector: 'app-form-array',
  templateUrl: './form-array.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ReactiveFormsModule, TooltipModule, NgTemplateOutlet, NgIf, NgFor, ValidationErrorsComponent, FaIconComponent],
  viewProviders: [{ provide: ControlContainer, useExisting: FormGroupDirective }]
})
export class FormArrayComponent {

  @ContentChildren('arrayFormGroupFieldsRef')
  set arrayFormGroupFieldRefs(refs: QueryList<TemplateRef<any>>) { this._arrayFormGroupFieldRefs = refs }
  get arrayFormGroupFieldRefs() { return this._arrayFormGroupFieldRefs }
  private _arrayFormGroupFieldRefs: QueryList<TemplateRef<any>>;

  @Input({ required: true }) arrayName: string;
  @Input({ required: true }) arrayFormGroup: ArrayFormGroup | (() => ArrayFormGroup);
  @Input({ required: true }) disabled: boolean;
  @Input({ required: true }) labelAddButton: string;
  @Input() label: string;
  @Input() enableOrdering = false;

  constructor(
    private formBuilder: FormBuilder,
    public formGroupDirective: FormGroupDirective,
    public controlContainer: ControlContainer
  ) {}

  moveUp(position: number) {
    let new_position = position - 1;

    if (position === 0) {
      new_position = this.arrayFormGroupFieldRefs.length - 1;
    }

    this.move(position, new_position);
  }

  moveDown(position: number) {
    let new_position = position + 1;

    if (position === this.arrayFormGroupFieldRefs.length - 1) {
      new_position = 0;
    }

    this.move(position, new_position);
  }

  removeFormGroup(index: number) {
    (this.formGroupDirective.form.get(this.arrayName) as FormArray).removeAt(index);
  }

  addFormGroup() {
    (this.formGroupDirective.form.get(this.arrayName) as FormArray).insert(
      (this.formGroupDirective.form.get(this.arrayName) as FormArray).controls.length,
      this.createFormGroup()
    );
    this.formGroupDirective.form.markAsDirty();
  }

  private createFormGroup(): FormGroup {
    return this.formBuilder.group(typeof this.arrayFormGroup === 'function' ? this.arrayFormGroup() : this.arrayFormGroup);
  }

  private move(oldPosition: number, newPosition: number) {
    const groups = this.formGroupDirective.form.get(this.arrayName) as FormArray;
    const currentGroup = groups.at(oldPosition);
    groups.removeAt(oldPosition);
    groups.insert(newPosition, currentGroup);
  }
}
