<ng-container *ngIf="arrayFormGroupFieldRefs?.length > 0">
  <div *ngFor="let ref of arrayFormGroupFieldRefs; let i = index" class="array-item">
    <div class="repeat-section-remove">
      <button type="button"
              class="close"
              aria-label="Remove"
              (click)="removeFormGroup(i)"
              [disabled]="disabled">
        <fa-icon [icon]="['fas', 'times-circle']"
                  transform="shrink-3"
                  [fixedWidth]="true"
                  aria-hidden="true"
                  i18n-tooltip="@@common.remove" tooltip="Remove"
                  container="body"
                  containerClass="danger">
        </fa-icon>
      </button>
      <button type="button"
              class="close up"
              aria-label="Move up"
              (click)="moveUp(i)"
              [disabled]="disabled"
              *ngIf="enableOrdering && arrayFormGroupFieldRefs?.length > 1">
        <fa-icon [icon]="['fas', 'chevron-circle-up']"
                  transform="shrink-3"
                  [fixedWidth]="true"
                  aria-hidden="true"
                  i18n-tooltip="@@common.move_up" tooltip="Move up"
                  container="body">
        </fa-icon>
      </button>
      <button type="button"
              class="close down"
              aria-label="Move down"
              (click)="moveDown(i)"
              [disabled]="disabled"
              *ngIf="enableOrdering && arrayFormGroupFieldRefs?.length > 1">
        <fa-icon [icon]="['fas', 'chevron-circle-down']"
                  transform="shrink-3"
                  [fixedWidth]="true"
                  aria-hidden="true"
                  i18n-tooltip="@@common.move_down" tooltip="Move down"
                  container="body">
        </fa-icon>
      </button>
    </div>
    <div class="row">
      <ng-container *ngTemplateOutlet="ref"></ng-container>
    </div>
  </div>
</ng-container>
<div class="repeat-section-button">
  <button type="button" class="btn btn-xs btn-dark" (click)="addFormGroup()" [disabled]="disabled">{{ labelAddButton }}</button>
</div>
<validation-errors *ngIf="formGroupDirective.form.get(arrayName)?.invalid && (formGroupDirective.form.get(arrayName)?.dirty || formGroupDirective.form.get(arrayName)?.touched)" [errors]="formGroupDirective.form.get(arrayName)?.errors" [label]="label" class="invalid-feedback"></validation-errors>
